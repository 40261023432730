
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import NotificationCard from "../Notification-Card.vue";

export default defineComponent({
  name: "CustomerFieldServices",
  components: {
    NotificationCard,
  },
  created() {
    this.getFieldServiceControl();
  },
  methods: {
    ...mapActions({
      getFieldServiceControl: "fieldServiceControl/getFieldServiceControl",
    })
  },
});
